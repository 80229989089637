<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row>
        <b-col lg="8">
          <b-form ref="firstTabForm" @submit="onSubmit" @reset="onReset">
            <!-- 贴片类型 -->
            <b-form-group
              label="Patch Type:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="patch-type"
              label-class="requiredRow"
            >
              <div class="d-flex flex-wrap">
                <b-form-checkbox
                  v-for="(label, index) in types.patchType"
                  :required="true"
                  :key="index"
                  v-model="form.type"
                  :value="label.id"
                  class="mr-2 mt-2"
                  @change="changeDeviceId(label.id)"
                >
                  {{ label.typeName }}
                </b-form-checkbox>
              </div>
            </b-form-group>

            <!-- 设备型号 -->
            <b-form-group
              label="Device Model:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="device-model"
              label-class="requiredRow"
            >
              <b-form-select
                id="device-model"
                v-model="form.deviceId"
                :options="types.deviceModel"
                value-field="id"
                text-field="modelName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
            </b-form-group>
            <!-- 最大吸入秒数 -->
            <b-form-group
              label="Max seconds:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="maxFrequency"
              label-class="requiredRow"
              description="To Prevent Counterfeit by Refill, Cartridge Will Stop Working After Time Reaches."
            >
              <b-form-input
                id="maxFrequency"
                v-model="form.maxFrequency"
                type="number"
                min="0"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');if(value>60000)value=60000;"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Voltage1:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="curveOne"
              label-class="requiredRow"
            >
              <div class="voltage-one">
                <div
                  class="voltage-number"
                  v-for="(item, index) in temperatureList"
                  :key="index"
                >
                  {{ item.value }} v
                </div>
              </div>

              <div class="voltage-two" style="width: 100%">
                <!--               @touchmove.prevent="(e)=>{}" -->

                <div
                  class="voltage-list"
                  v-for="(item, index) in temperatureList"
                  :key="index"
                >
                  <i
                    class="el-icon-plus voltage-plus"
                    @click="onVoltagePlus(index)"
                  ></i>

                  <el-slider
                    v-model="item.value"
                    vertical
                    :step="step"
                    :min="min"
                    :max="max"
                    height="150px"
                  >
                  </el-slider>

                  <i
                    class="el-icon-minus voltage-reduce"
                    @click="onVoltageReduce(index)"
                  ></i>
                </div>
              </div>

              <div class="voltage-three">
                <div class="voltage-number" v-for="index of 8" :key="index">
                  {{ index }}s
                </div>
              </div>
              <p>
                The Voltage curve is between 0.1v-4.2v, after the 8th second,
                the same as the 8th second
              </p>
            </b-form-group>

            <!-- 曲线二 -->
            <!-- <b-form-group
              label="Voltage curve:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="curveTwo"
              description="For Voltage curve value, enter the actual voltage value multiplied by 1000. For example, if it is 2.4V, enter 2400,please fill in between 100 and 4200"
            >
              <span
                v-for="(item, index) in curveListTwo"
                :key="index"
                class="col-md-2 col-2"
                style="display: inline-block; padding-left: 0"
              >
                <label>{{ index + 1 + " " + "seconds" }}</label>
                <b-form-input
                  v-model="item.value"
                  type="number"
                  min="0"
                  step="100"
                  placeholder="Please enter"
                  title=""
                  @change="changeCurveListTwo(curveListTwo)"
                  oninvalid="setCustomValidity('Please enter')"
                  oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                  onblur="if(value<100||value>4200)value=''"
                ></b-form-input>
              </span>
            </b-form-group> -->

            <!-- Preheat 是否打开Nfc设置 -->
            <b-form-group
              label="Voltage2 Settings:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="voltage-settings"
              label-class="requiredRow"
            >
              <b-form-checkbox
                id="voltage-settings"
                v-model="voltageSettings"
                name="voltageSetting"
                :value="1"
                :unchecked-value="0"
                switch
                size="lg"
                style="margin-top: 5px"
              >
                {{ voltageSettings == 1 ? "Yes" : "No" }}
              </b-form-checkbox>
            </b-form-group>
            <b-form-group
              v-if="voltageSettings == 1"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="curveTwo"
            >
              <div class="voltage-one">
                <div
                  class="voltage-number"
                  v-for="(item, index) in temperatureList1"
                  :key="index"
                >
                  {{ item.value }} v
                </div>
              </div>

              <div class="voltage-two" style="width: 100%">
                <!--               @touchmove.prevent="(e)=>{}" -->

                <div
                  class="voltage-list"
                  v-for="(item, index) in temperatureList1"
                  :key="index"
                >
                  <i
                    class="el-icon-plus voltage-plus"
                    @click="onVoltagePlus1(index)"
                  ></i>

                  <el-slider
                    v-model="item.value"
                    vertical
                    :step="step"
                    :min="min"
                    :max="max"
                    height="150px"
                  >
                  </el-slider>

                  <i
                    class="el-icon-minus voltage-reduce"
                    @click="onVoltageReduce1(index)"
                  ></i>
                </div>
              </div>

              <div class="voltage-three">
                <div class="voltage-number" v-for="index of 8" :key="index">
                  {{ index }}s
                </div>
              </div>
              <p>
                The Voltage curve is between 0.1v-4.2v, after the 8th second,
                the same as the 8th second
              </p>
            </b-form-group>
            <!-- 档数 高档或者低档 -->
            <b-form-group
              v-if="voltageSettings == 1"
              label="Position:"
              ref="groupNumberSetting"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="position"
              label-class="requiredRow"
            >
              <div class="d-flex flex-wrap">
                <b-form-checkbox
                  v-for="(label, index) in types.gearSetting"
                  :required="true"
                  :key="index"
                  v-model="form.position"
                  :value="label.value"
                  class="mr-2 mt-2"
                >
                  {{ label.text }}
                </b-form-checkbox>
              </div>
            </b-form-group>
            <b-col lg="12" style="text-align: center">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" style="margin-left: 30px">Cancel</b-button>
            </b-col>
          </b-form>
        </b-col>
        <!-- end col -->
      </b-row>
    </b-card>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import HistoryCurve from "@/components/HistoryCurve.vue";
/**
 * Cartridge Batch Add component
 */

export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    HistoryCurve,
  },
  computed: {
    is_add: function () {
      return /add/i.test(location.href);
    },
  },
  created() {
    this.init();
  },
  data() {
    return {
      title: "Patch Voltage Create",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Patch Voltage Create",
          active: true,
        },
      ],
      types: {
        selectAllBrandIdentity: [],
        patchType: [
          { typeName: "Disposable", id: 3 },
          { typeName: "Replaceable", id: 7 },
        ],
        deviceModel: [{ modelName: "Please select", id: "" }],
        groupNumberSetting: [
          { groupName: "1", id: 1 },
          { groupName: "2", id: 2 },
          { groupName: "3", id: 3 },
          { groupName: "4", id: 4 },
          { groupName: "5", id: 5 },
          { groupName: "6", id: 6 },
          { groupName: "7", id: 7 },
          { groupName: "8", id: 8 },
          { groupName: "9", id: 9 },
          { groupName: "10", id: 10 },
          { groupName: "11", id: 11 },
          { groupName: "12", id: 12 },
          { groupName: "13", id: 13 },
          { groupName: "14", id: 14 },
          { groupName: "15", id: 15 },
        ],
        urlSetting: [
          {
            url: "Please select",
            id: "",
          },
          {
            url: "https://www.unicoreus.com/download/unicore",
            id: 1,
          },
          {
            url: "https://www.unicoreus.com/meta",
            id: 2,
          },
          {
            url: "https://my.nicovapeq.com/m",
            id: 3,
          },
          {
            url: "https://my.usvvape.com/usv",
            id: 4,
          },
        ],
        gearSetting: [
          { text: "High position", value: 2 },
          { text: "Low position", value: 1 },
        ],
      },
      form: {
        type: 3,
        maxFrequency: "500",
        curveOne: "",
        curveTwo: "",
        position: 2,
        deviceId: "",
      },
      temperatureList: [
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
      ],
      temperatureList1: [
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
      ],
      min: 0.1,
      max: 4.2,
      step: 0.1,
      voltageSettings: 0,
      groupNumberSetting: 0,
      customWriteTag: 0,
    };
  },
  methods: {
    init() {
      if (!this.is_add) {
        this.$api.patchOrder
          .selectPatchVoltageById({
            id: this.$route.query.id,
          })
          .then((res) => {
            if (res.success && res.data) {
              this.form = res.data;
              var jsonArray = JSON.parse(res.data.voltage);
              if (jsonArray.length > 1) {
                this.temperatureList = jsonArray[0].curveValue
                  .split(",")
                  .map((value) => ({ value: parseFloat(value) }));
                this.temperatureList1 = jsonArray[1].curveValue
                  .split(",")
                  .map((value) => ({ value: parseFloat(value) }));
              } else {
                this.temperatureList = jsonArray[0].curveValue
                  .split(",")
                  .map((value) => ({ value: parseFloat(value) }));
              }
              if (res.data.position > 0) {
                this.voltageSettings = 1;
              }
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
      }

      this.$api.Dropdown.selectAllTestSmokeBombModel({
        type: this.$route.query.type,
      }).then((res) => {
        if (res.success && res.data) {
          this.types.deviceModel = this.types.deviceModel.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (!this.form.type) {
        this.$bvToast.toast("Please select Patch Type.");
        return;
      }
      // if (!this.form.brandIdentityId) {
      //   this.$bvToast.toast("Please select Brand Identity.");
      //   return;
      // }
      if (this.voltageSettings == 1) {
        if (!this.form.position) {
          this.$bvToast.toast("Please select Position.");
          return;
        }
      }
      this.form.curveOne = this.temperatureList
        .map((item) => item.value)
        .join(",");
      if (this.voltageSettings == 0) {
        this.form.position = 0;
        this.form.curveTwo = null;
      } else {
        this.form.curveTwo = this.temperatureList1
          .map((item) => item.value)
          .join(",");
      }

      this.$api.patchOrder.saveOrUpdatePatchVoltage(this.form).then((res) => {
        if (res.success) {
          this.$router.go(-1);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    changeDeviceId(deviceId) {
      this.types.deviceModel = [{ modelName: "Please select", id: "" }];
      this.$api.Dropdown.selectAllTestSmokeBombModel({
        type: deviceId,
      }).then((res) => {
        if (res.success && res.data) {
          this.types.deviceModel = this.types.deviceModel.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.$router.go(-1);
    },
    onVoltagePlus(index) {
      this.temperatureList[index].value = parseFloat(
        (this.temperatureList[index].value + 0.1).toFixed(1)
      );
    },
    onVoltageReduce(index) {
      this.temperatureList[index].value = parseFloat(
        (this.temperatureList[index].value - 0.1).toFixed(1)
      );
    },
    onVoltagePlus1(index) {
      this.temperatureList1[index].value = parseFloat(
        (this.temperatureList1[index].value + 0.1).toFixed(1)
      );
    },
    onVoltageReduce1(index) {
      this.temperatureList1[index].value = parseFloat(
        (this.temperatureList1[index].value - 0.1).toFixed(1)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.required-icon {
  ::before {
    content: "*";
  }
}

::v-deep .van-slider {
  margin: 10px auto;
}

.slider-button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffffff;
}

.voltage-two {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .voltage-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;

    .voltage-plus {
      margin-bottom: 20px;
    }

    .voltage-reduce {
      margin-top: 16px;
    }
  }
}
.voltage-one {
  margin-top: 10px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
  }
}
.voltage-three {
  margin-top: 66px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: hsla(0, 19%, 58%, 0);
  }
}
.temperature {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-slider__button {
  border: 2px solid #86a1a9;
}
::v-deep .el-slider__bar {
  background: #86a1a9;
}
</style>
